import {
  get,
  post
} from '../utils/http';

/**
 * 岗位管理
 * @param {*} data
 * @returns
 */
export function getgwglpagelist(data) {
   return get('/qgzx/SY_QGZX_GW/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
export function getgwglformdata(data) {
  return get('/qgzx/SY_QGZX_GW/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
export function delgwgl(data) {
  return post('/qgzx/SY_QGZX_GW/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
export function savegwgl(data) {
  return post('/qgzx/SY_QGZX_GW/Save', data);
}

// 获取请假管理流程
export function GetStep() {
    return get('/qgzx/SY_QGZX_LCPZ/GetStep/');
}

// 提交违纪流程配置
export function LCPZSave(data) {
  return post('/qgzx/SY_QGZX_LCPZ/LCPZSave/', data);
}

/**
 * 学生获取岗位
 * @param {*} data
 * @returns
 */
export function getxsgwpagelist(data) {
   return get('/qgzx/SY_QGZX_XSGW/GetPageList', data);
}

/**
 * 学生获取可申请岗位
 * @param {*} data
 * @returns
 */
export function getallowpagelist(data) {
   return get('/qgzx/SY_QGZX_GW/GetList', data);
}

// 提交岗位申请
export function GWSQSave(data) {
  return post('/qgzx/SY_QGZX_XSSQ/Save/', data);
}

/**
 * 学生获取已申请岗位
 * @param {*} data
 * @returns
 */
export function getxssqpagelist(data) {
   return get('/qgzx/SY_QGZX_XSSQ/GetList', data);
}

/**
 * 学生获取岗位详细信息
 * @param {*} data
 * @returns
 */
export function getxssqdetail(data) {
   return get('/qgzx/SY_QGZX_XSSQ/GetFormData', data);
}

/**
 * 删除申请
 * @param {*} data
 * @returns
 */
export function deletexssq(data) {
   return post('/qgzx/SY_QGZX_XSSQ/Delete', data);
}

/**
 * 获取待审批数据
 * @param {*} data
 * @returns
 */
export function getgwsppagelist(data) {
   return get('/qgzx/SY_QGZX_XSSQ/GetPageList', data);
}

/**
 * 获取待审批数据表单数据
 * @param {*} data
 * @returns
 */
export function GetSPFormData(data) {
   return get('/qgzx/SY_QGZX_XSSQ/GetSPFormData', data);
}

/**
 * 提交审批
 * @param {*} data
 * @returns
 */
export function savesp(data) {
   return post('/qgzx/SY_QGZX_XSSQ/savesp', data);
}

/**
 * 获取岗位申请列表
 * @param {*} data
 * @returns
 */
export function getdwgwsqpagelist(data) {
   return get('/qgzx/SY_QGZX_GWSQ/GetDWPageList', data);
}

/**
 * 获取岗位申请列详情
 * @param {*} data
 * @returns
 */
export function getgwsqformpagelist(data) {
   return get('/qgzx/SY_QGZX_GWSQ/GetFormData', data);
}

/**
 * 撤销申请
 * @param {*} data
 * @returns
 */
export function delgwsq(data) {
   return get('/qgzx/SY_QGZX_GWSQ/Delete', data);
}

/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
export function savegwsq(data) {
  return post('/qgzx/SY_QGZX_GWSQ/Save', data);
}

/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
export function savegwsp(data) {
  return post('/qgzx/SY_QGZX_GWSQ/SaveSP', data);
}

/**
 * 获取岗位申请列表
 * @param {*} data
 * @returns
 */
export function getgwsqpagelist(data) {
   return get('/qgzx/SY_QGZX_GWSQ/GetPageList', data);
}
